import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import createPersistedState from "vuex-persistedstate";
import * as Cookies from "js-cookie";
// const BASE_URL = "http://localhost:8000/api";
const BASE_URL = "http://payroll.chedroxi.com/api";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    Sidebar_drawer: true,
    Customizer_drawer: false,
    SidebarColor: "white", //Change Sidebar Color || 'white', | "#2b2b2b" | "rgb(44, 59, 164)" | "rgb(96, 44, 164)" | "rgb(151, 210, 219)" | "rgb(77, 86, 100)"
    SidebarBg: "",
    navbarColor: "primary",
    setHorizontalLayout: false, // Horizontal layout

    user: [],
    user_profile: {},
    loading: false,
    is_authenticated: false,
    total_payroll: 0,
    token: [],
  },
  mutations: {
    SET_SIDEBAR_DRAWER(state, payload) {
      state.Sidebar_drawer = payload;
    },
    SET_CUSTOMIZER_DRAWER(state, payload) {
      state.Customizer_drawer = payload;
    },
    SET_SIDEBAR_COLOR(state, payload) {
      state.SidebarColor = payload;
    },
    SET_NAVBAR_COLOR(state, payload) {
      state.navbarColor = payload;
    },
    SET_LAYOUT(state, payload) {
      state.setHorizontalLayout = payload;
    },

    isLoading(state, payload){
      state.loading = payload
    },
    isAuthenticated(state, payload){
      state.is_authenticated = payload;
    },
    

    setToken(state, payload){
      state.token = payload;
    },
    setUser(state, payload){
      state.user = payload;
    },
    setUserProfile(state, payload){
      state.user_profile = payload;
    },
    setRequestHeader(state, payload){
      axios.defaults.headers.common["Authorization"] = `Bearer ${payload}`;
    },
    clearCredentials(state){
      state.user = [];
      state.token = [];
      state.is_authenticated = false;
    },

    setTotalNumberOfPayroll(state, payload){
      state.total_payroll = payload;
    }
  },
  actions: {
    /**
     * Login and Logout
     */
     signIn(context, payload){
      context.commit('isLoading', true);
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          axios.post(`${BASE_URL}/user/auth/signin`, payload)
          .then((response) => {
            context.commit('isLoading', false);
            context.commit('isAuthenticated', true);
            context.commit('setToken', response.data);
            resolve(response);
          })
          .catch((error) => {
            context.commit('isLoading', false);
            context.commit('isAuthenticated', false);
            reject(error);
          })
        }, 1000)
      })
    },

    async signingIn({dispatch, commit, getters },payload){
      await dispatch('signIn', payload);
      await dispatch('getUserInfo', getters.token)
      .then((response) => {
        // this.setIsPasswordChange(response.data.is_password_changed);
        // this.redirectToHeiDashboard();
        commit('setUser', response.data);
      })
      .catch((error) => {
        console.log(error);
      })
    },
    
    signOut({commit, getters}){
      return new Promise((resolve, reject) => {
        commit("isLoading", true);
        axios.post(`${BASE_URL}/app/payroll/auth/sign-out`, null , { headers: {"Authorization" : `Bearer ${getters.token.access_token}`}})
        .then((response) => {
          commit('clearCredentials');
          commit("isLoading", false);
          resolve(response);
        })
        .catch((error) => {
          commit('clearCredentials');
          commit("isLoading", false);
          reject(error);
        });
      })
    },

    /**
     * Login and Logout Ends
     */


    

    /**
     * Getting Current User Info
     */
    getUserInfo(context, payload){
      return new Promise ((resolve, reject) => {
        axios.get(`${BASE_URL}/app/payroll/get-user`, { headers: {"Authorization" : `Bearer ${payload.access_token}`}})
        .then((response) => {
          resolve(response);
        })
        .catch((error)=>{
          reject(error);
        });
      });
    },

    /**
     * Getting Current User Info Ends
     */

    
    /** Payroll Starts*/
    
    getAllPayrolls(context, payload){
      context.commit('setRequestHeader', context.getters.token.access_token);
      return new Promise ((resolve, reject) => {
        context.commit("isLoading", true);
        axios.get(`${BASE_URL}/app/payroll/get/all`, { params : payload })
        .then((response) => {
          context.commit("isLoading", false);
          resolve(response);
        })
        .catch((error)=>{
          context.commit("isLoading", false);
          reject(error);
        });
      });
    },


    getAllPayrollsByEmployee(context, payload){
      context.commit('setRequestHeader', context.getters.token.access_token);
      return new Promise ((resolve, reject) => {
        context.commit("isLoading", true);
        axios.get(`${BASE_URL}/app/payroll/get/all/payrolls/by_employee`, { params : payload })
        .then((response) => {
          context.commit("isLoading", false);
          resolve(response);
        })
        .catch((error)=>{
          context.commit("isLoading", false);
          reject(error);
        });
      });
    },


    getPayrollDetails(context, payload){
      context.commit('setRequestHeader', context.getters.token.access_token);
      return new Promise ((resolve, reject) => {
        context.commit("isLoading", true);
        axios.get(`${BASE_URL}/app/payroll/details/get/${payload}`, { params : payload })
        .then((response) => {
          context.commit("isLoading", false);
          resolve(response);
        })
        .catch((error)=>{
          context.commit("isLoading", false);
          reject(error);
        });
      });
    },


    getPayrollDetailPerEmployee(context, payload){
      context.commit('setRequestHeader', context.getters.token.access_token);
      return new Promise ((resolve, reject) => {
        context.commit("isLoading", true);
        axios.get(`${BASE_URL}/app/payroll/detail/per_employee/get/${payload}`, { params : payload })
        .then((response) => {
          context.commit("isLoading", false);
          resolve(response);
        })
        .catch((error)=>{
          context.commit("isLoading", false);
          reject(error);
        });
      });
    },


    createPayroll(context, payload){
      context.commit('setRequestHeader', context.getters.token.access_token);
      return new Promise ((resolve, reject) => {
        context.commit("isLoading", true);

        let formData = new FormData();
        formData.append('file', payload.selected_file);
        formData.append('month', payload.month);

        axios.post(`${BASE_URL}/app/payroll/create`, formData , {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          context.commit("isLoading", false);
          resolve(response);
        })
        .catch((error)=>{
          context.commit("isLoading", false);
          reject(error);
        });
      });
    },

    updatePayroll(context, payload){
      context.commit('setRequestHeader', context.getters.token.access_token);
      return new Promise ((resolve, reject) => {
        context.commit("isLoading", true);

        let formData = new FormData();
        console.log(payload.selected_file);
        formData.append('file', payload.selected_file);
        formData.append('month', payload.month);

        axios.post(`${BASE_URL}/app/payroll/update/${payload.uuid}`, formData , {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          context.commit("isLoading", false);
          resolve(response);
        })
        .catch((error)=>{
          context.commit("isLoading", false);
          reject(error);
        });
      });
    },

    deletePayroll(context, payload){
      context.commit('setRequestHeader', context.getters.token.access_token);
      return new Promise ((resolve, reject) => {
        axios.delete(`${BASE_URL}/app/payroll/delete/${payload.uuid}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error)=>{
          reject(error);
        });
      });
    },

    generatePayroll(context, payload){
      context.commit('setRequestHeader', context.getters.token.access_token);
      return new Promise ((resolve, reject) => {
        axios.post(`${BASE_URL}/app/payroll/fetch/payroll`, null, {params: {payroll_id : payload}})
        .then((response) => {
          resolve(response);
        })
        .catch((error)=>{
          reject(error);
        });
      });
    },
    /** Payroll Ends */

    /** Account Profile */
    getAccountProfile(context){
      context.commit('setRequestHeader', context.getters.token.access_token);
      return new Promise ((resolve, reject) => {
        context.commit("isLoading", true);
        axios.get(`${BASE_URL}/app/user/account/profile`)
        .then((response) => {
          context.commit("isLoading", false);
          resolve(response);
        })
        .catch((error)=>{
          context.commit("isLoading", false);
          reject(error);
        });
      });
    },

    updateAccountProfile(context, payload){
      context.commit('setRequestHeader', context.getters.token.access_token);
      return new Promise ((resolve, reject) => {
        context.commit("isLoading", true);
        axios.post(`${BASE_URL}/app/user/account/profile/update`, null, { params: payload })
        .then((response) => {
          context.commit("isLoading", false);
          resolve(response);
        })
        .catch((error)=>{
          context.commit("isLoading", false);
          reject(error);
        });
      });
    },

    updateAccountPassword(context, payload){
      context.commit('setRequestHeader', context.getters.token.access_token);
      return new Promise ((resolve, reject) => {
        context.commit("isLoading", true);
        axios.post(`${BASE_URL}/app/user/account/update/password`, null, {params: payload})
        .then((response) => {
          context.commit("isLoading", false);
          resolve(response);
        })
        .catch((error)=>{
          context.commit("isLoading", false);
          reject(error);
        });
      });
    },

    updateAccountProfilePhoto(context, payload){
      context.commit('setRequestHeader', context.getters.token.access_token);
      return new Promise ((resolve, reject) => {
        context.commit("isLoading", true);
        let formData = new FormData();
        formData.append('photo', payload);
        axios.post(`${BASE_URL}/app/user/account/profile/photo/update`, formData , {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          context.commit("isLoading", false);
          resolve(response);
        })
        .catch((error)=>{
          context.commit("isLoading", false);
          reject(error);
        });
      });
    },
    
    /** Account Profile Ends */
    
    /** Dashboard */
    getDashboardActivityTimeline(context, payload){
      context.commit('setRequestHeader', context.getters.token.access_token);
      return new Promise ((resolve, reject) => {
        context.commit("isLoading", true);
        let formData = new FormData();
        formData.append('photo', payload);
        axios.get(`${BASE_URL}/app/payroll/dashboard/activities/get`)
        .then((response) => {
          context.commit("isLoading", false);
          resolve(response);
        })
        .catch((error)=>{
          context.commit("isLoading", false);
          reject(error);
        });
      });
    },
    /**Dashboard Ends */

    /** Activity Timeline */
    deleteActivity(context, payload){
      context.commit('setRequestHeader', context.getters.token.access_token);
      return new Promise ((resolve, reject) => {
        axios.delete(`${BASE_URL}/app/payroll/dashboard/activity/delete/${payload.id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error)=>{
          reject(error);
        });
      });
    },

    /** Activity Timeline Ends */

    /** User Notifications */
    getNotifications(context, payload){
      context.commit('setRequestHeader', context.getters.token.access_token);
      return new Promise ((resolve, reject) => {
        axios.get(`${BASE_URL}/app/user/notifications/get`)
        .then((response) => {
          resolve(response);
        })
        .catch((error)=>{
          reject(error);
        });
      });
    },

    /** User Notifications Ends */

  },
  getters: {
    isLoading(state) {
      return state.loading;
    },
    isAuthenticated(state){
      return state.is_authenticated;
    },
    token(state){
      return state.token;
    },
    getUser(state){
      return state.user;
    },
    getUserProfile(state){
      return state.user_profile;
    },
    getTotalNumberOfPayroll(state){
      return state.total_payroll;
    }
  },

  modules: {},
  plugins: [createPersistedState({
    storage: window.sessionStorage
    // storage:{
    //   getItem: (key) => Cookies.get(key),
    //   setItem: (key, value) =>
    //     Cookies.set(key, value, { expires: 3, secure: true }),
    //   removeItem: (key) => Cookies.remove(key),
    // }
})],
});
