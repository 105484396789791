import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store/store";
import Vuebar from "vuebar";
import "./plugins/base";
import VueSkycons from "vue-skycons";
import InstantSearch from "vue-instantsearch";
import moment from 'moment';
import VueMask from 'v-mask';
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';


import {
  ValidationObserver,
  ValidationProvider,
} from "vee-validate";

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("Cropper", Cropper);


/** Vue filters starts */
Vue.prototype.moment = moment;

Vue.filter('lower', function(text){
    return text.toLowerCase();
});

 // Feb 25, 2021 10:19 AM
Vue.filter('formatDate', function(date){
    return moment(date).format('lll');
});

 // February 25, 2021
Vue.filter('regularDate', function(date){
    return moment(date).format('LL');
});

 // February 25, 2021 10:19 AM
Vue.filter('regularDateWithTime', function(date){
    return moment(date).format('LLL');
});

 // Today at 10:20 AM
Vue.filter('calendarDate', function(date){
    return moment(date).calendar();
});

Vue.filter('dateOnly', function(date){
    return moment(date).format('MM-DD-YYYY');
});

Vue.filter('month', function(date){
    return moment(date).format('MMMM YYYY');
});

Vue.filter('full_month', function(date){
    return moment(date).format('MMMM');
});

Vue.filter('full_year', function(date){
    return moment(date).format('YYYY');
});
/** Vue filters ends */



Vue.use(VueSkycons, {
  color: "#1e88e5",
});
Vue.use(InstantSearch);
Vue.config.productionTip = false;
Vue.use(Vuebar);
Vue.use(VueMask);

new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
