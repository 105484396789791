import Vue from "vue";
import Router from "vue-router";
import goTo from "vuetify/es5/services/goto";

Vue.use(Router);

import Store from "@/store/store.js";
const store = Store;

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  // This is for the scroll top when click on any router link
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },
  // This is for the scroll top when click on any router link
  routes: [
    {
      path: "/",
      redirect: "dashboard/main",
      name: "mainApp",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        {
          name: "Analytical",
          path: "dashboard/main",
          component: () => import("@/views/ched/Dashboard.vue"),
        },
        {
          name: "Payroll",
          path: "system/payroll",
          component: () => import("@/views/ched/Payroll.vue"),
        },
        {
          name: "PayrollPerEmployee",
          path: "system/payroll/list/per/employee",
          component: () => import("@/views/ched/PayrollPerEmployee.vue"),
        },
        {
          name: "PayrollDetail",
          path: "system/payroll/detail/:id",
          component: () => import("@/views/ched/PayrollDetail.vue"),
        },
        {
          name: "PayrollDetailPerEmployee",
          path: "system/payroll/detail/individual/:id",
          component: () => import("@/views/ched/PayrollDetailPerEmployee.vue"),
        },
        {
          name: "AccountProfile",
          path: "user/account/profile/view",
          component: () => import("@/views/ched/AccountProfile.vue"),
        },
      ],
      meta: {
        permission: 'auth'
      }
    },

    {
      path: "/authentication/user",
      component: () => import("@/layouts/blank-layout/Blanklayout"),
      children: [
        {
          name: "BoxedLogin",
          path: "sign-in",
          component: () => import("@/views/authentication/BoxedLogin"),
        }
      ],
      meta: {
        permission: 'guest'
      }
    },
    {
      path: "/link/not_found",
      name: 'url-not-found',
      component: () => import("@/views/authentication/Error"),
    },
    {
      path: "*",
      component: () => import("@/views/authentication/Error"),
    },
  ],
});

import NProgress from "nprogress";

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start(800);
  }

  if(store.getters.isAuthenticated && to.path == "/authentication/user/sign-in"){
    next({name: "mainApp"});
  }
  // if(store.getters.isAuthenticated && Number(store.getters.getUser.user_type) === 2 && to.path.includes('/system/payroll/detail/')){
  //   next({name: "url-not-found"});
  // }
  else if(to.matched.some(record => record.meta.permission === "auth") && !store.getters.isAuthenticated){
    next({name: "BoxedLogin"});
  }
  else if(to.matched.some(record => record.meta.permission === "guest")){
    next();
  }
  else{
    next();
  }


});

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done();
});

export default router;
